import styled, { css } from "styled-components";

const labelCSS = css`
  font-size: 14px;
  white-space: nowrap;
  font-weight: 600;
`;

export const SectionLabel = styled.label`
  ${labelCSS}
  display: block;
  margin: 20px 0;
  text-transform: capitalize;
  color: #aaa;
  & + p {
    font-size: 12px;
    text-align: justify;
  }
`;

export const ElementLabel = styled.label`
  ${labelCSS}
`;
