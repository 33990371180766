import {
  Dispatch,
  forwardRef,
  ReactNode,
  RefObject,
  useCallback,
  useEffect,
  useRef,
  useState
} from "react";
import { Map, MapLayerMouseEvent, MapRef, ViewStateChangeEvent } from "react-map-gl";
import styled from "styled-components";

import { useQuery } from "@apollo/client";
import MapSettings from "@src/AdminDashboard/MapSettings/Settings.graphql";
import { SecondaryButton } from "@src/Components/Buttons/Secondary";
import { SkeletonBorder } from "@src/Components/Skeleton";
import { MapOverlayText } from "@src/Components/Text";
import { MapSettingsQuery } from "@src/Generated/graphql";
import { useMapboxApiToken } from "@src/Hooks/apiToken";
import { useKeyPress } from "@src/Hooks/keyPress";
import { checkMapReady, MapAction, MapState } from "@src/Map/mapReducer";

const MapContainer = styled.div<{ $mapHeight: string }>`
  height: ${({ $mapHeight }) => $mapHeight || "600px"};
  grid-column: 1 / -1;
  display: grid;
  position: relative;
`;

export const MapButton = styled(SecondaryButton).attrs({ type: "button" })`
  width: 100px;
  height: 30px;
  margin-left: 5px;
`;

const ResetPosition = styled(MapButton)`
  position: absolute;
  top: 10px;
  right: 10px;
`;

const ScrollInfo = styled(MapOverlayText)`
  top: 10px;
  left: 10px;
`;

interface MapboxMapProps {
  mapState: MapState;
  dispatch: Dispatch<MapAction>;
  onClick?: (e: MapLayerMouseEvent) => void;
  children?: ReactNode;
  interactiveLayerIds?: string[];
  mapHeight?: string;
  centerOnZoom?: boolean;
}

export const MapboxMap = forwardRef(function MapboxMap(
  {
    mapState,
    dispatch,
    onClick,
    children,
    interactiveLayerIds = [],
    mapHeight,
    centerOnZoom
  }: MapboxMapProps,
  ref: RefObject<MapRef>
) {
  const container = useRef<HTMLDivElement>(null);
  useEffect(() => {
    const { clientHeight: height, clientWidth: width } = container.current;
    if (height !== mapState.height || width != mapState.width) {
      dispatch({ type: "setDimensions", width, height });
    }
  });

  const onViewportChange = useCallback(
    (e: ViewStateChangeEvent) => {
      const { latitude, longitude, zoom } = e.viewState;
      dispatch({
        type: "setViewport",
        center: { lat: latitude, lng: longitude },
        zoom
      });
    },
    [dispatch]
  );

  const apiToken = useMapboxApiToken();
  const mapIsReady = apiToken && checkMapReady(mapState);

  const { data: mapSettingsData } = useQuery<MapSettingsQuery>(MapSettings);

  const [cursor, setCursor] = useState<string>("");
  const onMouseEnter = useCallback(() => setCursor("default"), [setCursor]);
  const onMouseLeave = useCallback(() => setCursor(""), [setCursor]);

  const resetMap = useCallback(() => {
    if (mapState.resetPosition) {
      const { latitude, longitude, zoom } = mapState.resetPosition;
      dispatch({
        type: "setViewport",
        center: { lat: latitude, lng: longitude },
        zoom
      });
    } else {
      const map = mapSettingsData?.settings?.map;
      if (!map) return;
      dispatch({
        type: "setViewport",
        center: { lat: map.center.lat, lng: map.center.lng },
        zoom: map.zoom
      });
    }
  }, [dispatch, mapSettingsData?.settings?.map, mapState.resetPosition]);

  const centerMap = useCallback(
    (e: ViewStateChangeEvent) => {
      if (mapState.resetPosition && centerOnZoom) {
        const { latitude, longitude } = mapState.resetPosition;
        const { zoom } = e.viewState;
        dispatch({
          type: "setViewport",
          center: { lat: latitude, lng: longitude },
          zoom
        });
      }
    },
    [dispatch, centerOnZoom, mapState?.resetPosition]
  );

  const localRef = useRef<MapRef>();
  const mapRef = ref || localRef;
  useEffect(() => {
    if (mapState.shouldFly) {
      const { longitude, latitude, zoom } = mapState.shouldFly;
      mapRef.current?.flyTo({ center: [longitude, latitude], zoom, duration: 1000 });
      dispatch({
        type: "setViewport",
        center: { lat: latitude, lng: longitude },
        zoom
      });
    }
  }, [mapRef, mapState.shouldFly, dispatch]);

  const ctrlKeyPressed = useKeyPress("Control");

  return (
    <MapContainer ref={container} $mapHeight={mapHeight}>
      {mapIsReady ? (
        <Map
          ref={mapRef}
          onClick={onClick}
          mapboxAccessToken={apiToken}
          scrollZoom={ctrlKeyPressed}
          {...mapState}
          onMove={onViewportChange}
          onZoomEnd={centerMap}
          attributionControl
          interactiveLayerIds={interactiveLayerIds}
          cursor={cursor}
          onMouseEnter={onMouseEnter}
          onMouseLeave={onMouseLeave}
          dragPan={!centerOnZoom}
        >
          {children}
          <ResetPosition onClick={resetMap}>reset view</ResetPosition>
          <ScrollInfo>Zoom: Ctrl+Scroll</ScrollInfo>
        </Map>
      ) : (
        <SkeletonBorder />
      )}
    </MapContainer>
  );
});
