import { ReviewFieldType } from "@src/Components/Review/FieldReview";

import { ConfigField } from "./initFormConfig";
import { FieldInfo } from "./RenderedInputs";

export function compareValueState(fieldInfo: Partial<FieldInfo>) {
  const { initialValue, value } = fieldInfo;
  const isDefaultUnsetValue = initialValue == null && (value === "" || value === false);
  return String(initialValue) === String(value) || isDefaultUnsetValue;
}

export function serialiseReviewFields(config: ConfigField[], isEdit: boolean) {
  const serialisedConfig = config.map(field => {
    const { value, initialValue, selected } = field;
    let reviewDisplayValue;
    let reviewType: ReviewFieldType;
    if (selected && !compareValueState(field)) {
      reviewDisplayValue = value === "" ? "❛❛ ❜❜" : value;
      reviewType = isEdit ? "modified" : "override";
    } else if (!selected && initialValue == null) {
      reviewDisplayValue = undefined;
      reviewType = "unset";
    } else {
      reviewDisplayValue = initialValue === "" ? "❛❛ ❜❜" : initialValue;
      reviewType = isEdit ? "unmodified" : "default";
    }
    return {
      ...field,
      reviewType,
      value: reviewDisplayValue
    };
  });
  return serialisedConfig;
}
