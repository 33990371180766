import { OrgSelector } from "@src/Components/OrgSelector";
import { useOrgCtx } from "@src/Hooks/Context/orgCtx";
import { useDefaultOrg } from "@src/Hooks/defaultOrg";
import { useSelectOrg } from "@src/Hooks/selectOrg";
import { useLoggedInUserOrgs } from "@src/Hooks/userOrgs";

import { HeaderItem } from "./HeaderStyles";

export const HeaderOrgSelector = () => {
  const orgCtx = useOrgCtx();
  const { org: defaultOrg } = useDefaultOrg();

  const { selectOrg } = useSelectOrg();
  const { userOrgs, loading } = useLoggedInUserOrgs();

  const selectedOrg = orgCtx ?? defaultOrg;

  if (!selectedOrg || loading) return null;

  const loadingOrgs = !(selectedOrg && userOrgs);

  return (
    <HeaderItem>
      Org:
      <OrgSelector
        selectedOrg={selectedOrg}
        organizations={userOrgs || []}
        selectOrg={selectOrg}
        loading={loadingOrgs}
      />
    </HeaderItem>
  );
};
