import { Suspense } from "react";
import { ErrorBoundary } from "react-error-boundary";
import { Outlet } from "react-router-dom";
import styled from "styled-components";

import AboutIcon from "@img/info-solid.svg";
import { DelayedRedirect } from "@src/Components/DelayedRedirect";
import { LoadingPage } from "@src/Components/Loading/LoadingPage";
import {
  Img,
  LoggedOutWarning,
  Logo,
  Main,
  Page,
  Sidebar
} from "@src/Components/Navigation/NavbarStyles";
import { NavItem } from "@src/Components/Navigation/NavItem";
import { useBrandingLogos } from "@src/Hooks/brandingLogos";
import { useCurrentDashboard, useGetDashboardBaseUrl } from "@src/Hooks/useCurrentDashboard";

import { AdminNavbar } from "./AdminDashboard/AdminNavbar";
import { panelPortalId } from "./Components/CloudConsole/panelPortalId";
import { bottomPanelZIndex } from "./Components/zIndexValues";
import { DashboardErrorFallback } from "./Error/DashboardErrorFallback";
import { Header } from "./Header";
import { LogsPanel } from "./LogsPanel/LogsPanel";
import { MECNavbar } from "./MECDashboard/MECNavBar";
import { MPNNavbar } from "./MPNDashboard/MPNNavbar";
import { ServicesNavbar } from "./ServicesDashboard/ServicesNavbar";
import { useSession } from "./Session";

const AboutLink = styled.div`
  margin-top: auto;
`;

const BottomPanelWrapper = styled.div`
  grid-area: panel;
  position: sticky;
  bottom: 0;
  z-index: ${bottomPanelZIndex};
`;

export function Layout() {
  const { isLoggedOut } = useSession();

  const logoUrl = useBrandingLogos("nav");

  const { isServices, isMPN, isAdminDashboard, isMEC } = useCurrentDashboard();
  const baseDashboardUrl = useGetDashboardBaseUrl();

  return (
    <Page>
      <Sidebar>
        <Logo to="/">
          <Img src={logoUrl} alt="logo" />
        </Logo>
        {isServices && <ServicesNavbar />}
        {isMPN && <MPNNavbar />}
        {isAdminDashboard && <AdminNavbar />}
        {isMEC && <MECNavbar />}
        <AboutLink>
          <NavItem linkTo={baseDashboardUrl + "about"} label="About" icon={AboutIcon} />
        </AboutLink>
      </Sidebar>
      <Header />
      <Main>
        {isLoggedOut ? (
          <>
            <LoggedOutWarning>You are not logged in. Redirecting to login page...</LoggedOutWarning>
            <DelayedRedirect delay={5000} to="/auth/login" />
          </>
        ) : null}
        <ErrorBoundary fallback={<DashboardErrorFallback />}>
          <Suspense fallback={<LoadingPage />}>
            <Outlet />
          </Suspense>
        </ErrorBoundary>
      </Main>
      <BottomPanelWrapper id={panelPortalId}>{isServices && <LogsPanel />}</BottomPanelWrapper>
    </Page>
  );
}
