import { useParams } from "react-router-dom";
import styled from "styled-components";

import { PrimaryTextButton } from "@src/Components/Buttons/Text";
import { ErrorLine } from "@src/Components/Input/InputGroup";
import { Loading } from "@src/Components/Loading/Loading";
import { Table, Td, Th, THead, Tr } from "@src/Components/Table/Table";
import { H2 } from "@src/Components/Text";
import { useFetch } from "@src/Hooks/fetch";
import { useToggle } from "@src/Hooks/toggle";

export interface License {
  Name: string;
  Version: string;
  License: string;
  LicenseText: string;
}

export function Licenses() {
  const { component } = useParams<{ component: string }>();
  const [licenses, loading, error] = useFetch<License[]>(`/licenses/${component}.json`);

  return (
    <>
      <H2>{component}</H2>
      {loading ? (
        <Loading />
      ) : error ? (
        <ErrorLine>failed to obtain licenses</ErrorLine>
      ) : licenses ? (
        <Table>
          <THead>
            <tr>
              <Th>Library</Th>
              <Th>Version</Th>
              <Th>License</Th>
            </tr>
          </THead>
          {licenses.map(license => (
            <LicenseRow key={license.Name} license={license} />
          ))}
        </Table>
      ) : null}
    </>
  );
}

const LicenseText = styled.div`
  margin: 1em auto;
  white-space: pre-wrap;
`;

interface LicenseRowProps {
  license: License;
}

export function LicenseRow({ license }: LicenseRowProps) {
  const { state: showLicenseText, toggle } = useToggle();

  return (
    <tbody>
      <Tr clickable selected={showLicenseText} onClick={toggle} key={license.Name}>
        <Td>{license.Name}</Td>
        <Td>{license.Version}</Td>
        <Td>
          <PrimaryTextButton>{license.License}</PrimaryTextButton>
        </Td>
      </Tr>
      {showLicenseText ? (
        <tr>
          <Td colSpan={3}>
            <LicenseText>{license.LicenseText}</LicenseText>
          </Td>
        </tr>
      ) : null}
    </tbody>
  );
}
