import styled, { css } from "styled-components";

import { PrimaryButton } from "./Buttons/Primary";

export const UnavailablePageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  position: sticky;
  top: 30%;
  gap: 20px;
`;
export const unavailableIconStyles = css`
  height: 200px;
  color: #999;
  fill: #999;
`;

export const NewResourceButton = styled(PrimaryButton)`
  background-color: ${({ theme }) => theme.green};
  &:hover {
    color: white;
    filter: brightness(85%);
  }
`;

export const UnavailableResourceMessage = styled.p`
  font-size: 16px;
  font-weight: 500;
`;
