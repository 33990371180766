import { NavLink } from "react-router-dom";
import styled from "styled-components";

import Home from "@img/house-solid.svg";
import { useOrgCtx } from "@src/Hooks/Context/orgCtx";
import { useDefaultOrg } from "@src/Hooks/defaultOrg";
import { useLandingPage } from "@src/Hooks/Permissions/landingPage";

const HomeIcon = styled(Home)`
  height: 20px;
  fill: ${({ theme }) => theme.primary};
`;

const HomeLink = styled(NavLink)`
  text-decoration: none;
  color: ${({ theme }) => theme.primary};
  border: 1px solid ${({ theme }) => theme.primary};
  display: flex;
  padding: 10px;
  margin: 6px auto 6px 0;
  align-items: center;
  font-weight: 600;
  gap: 10px;
  border-radius: 3px;

  &:hover {
    fill: white;
    color: white;
    border: 1px solid ${({ theme }) => theme.primary};
    background-color: ${({ theme }) => theme.primary};
  }
`;

export function HomeButton() {
  const orgCtx = useOrgCtx();

  const { org: defaultOrg } = useDefaultOrg();

  const org = orgCtx ?? defaultOrg;

  const { allowed: showLanding } = useLandingPage();

  return (
    showLanding && (
      <HomeLink to={`/app/${org}/home`}>
        <HomeIcon />
        <span>Home</span>
      </HomeLink>
    )
  );
}
