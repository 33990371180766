import { Namespace } from "@src/Generated/permissions/namespace";
import { Permit } from "@src/Generated/permissions/permit";

import { usePermissionCheck } from "../permissionsCheck";

export function useCanCreateSite(id: string) {
  return usePermissionCheck({
    objectType: Namespace.Org,
    objectId: id,
    relation: Permit.CreateSite
  });
}

export function useCanEditSite(id: string) {
  return usePermissionCheck({
    objectType: Namespace.Site,
    objectId: id,
    relation: Permit.Write
  });
}

export function useCanDeleteSite(id: string) {
  return usePermissionCheck({
    objectType: Namespace.Site,
    objectId: id,
    relation: Permit.Delete
  });
}
