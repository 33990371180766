import { Arrow } from "@src/Components/arrowStyles";
import { Skeleton } from "@src/Components/Skeleton";

import { Collapsible, TreeItem, TreeWrapper } from "./treeStyledComponents";

export function TreeSkeleton() {
  return (
    <TreeWrapper>
      <TreeItem>
        <Arrow $expanded={false}>
          <span />
        </Arrow>
        <Skeleton width={200} />
      </TreeItem>
      <Collapsible $isOpen>
        <TreeItem>
          <Arrow $expanded={false}>
            <span />
          </Arrow>
          <Skeleton width={200} />
        </TreeItem>
        <TreeItem>
          <Arrow $expanded={false}>
            <span />
          </Arrow>
          <Skeleton width={200} />
        </TreeItem>
      </Collapsible>
    </TreeWrapper>
  );
}
