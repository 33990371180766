import { useCallback } from "react";
import toast from "react-hot-toast";
import { useLocation, useNavigate } from "react-router-dom";

import { ToastNotification } from "@src/Components/ToastNotification";

import { redirectNetwork } from "./redirectNetwork";

export function useSelectNetwork() {
  const { pathname } = useLocation();
  const navigate = useNavigate();

  const selectNetwork = useCallback(
    (network: string) => {
      navigate(redirectNetwork(pathname, network));
      toast.success(<ToastNotification title={`Switched network`} />);
    },
    [navigate, pathname]
  );

  return {
    selectNetwork
  };
}
