import { useCallback } from "react";
import styled from "styled-components";

import { Clickable } from "@src/Components/Buttons/Clickable";
import { GhostButton } from "@src/Components/Buttons/Ghost";

const ModalWrapper = styled.div`
  position: absolute;
  background-color: white;
  margin-top: 10px;
  box-shadow: rgb(0, 0, 0, 0.5) 2px 2px 16px;
  font-size: small;
  font-weight: 600;
`;

const ModalHeader = styled.div`
  width: 100%;
  box-sizing: border-box;
  border-bottom: 1px solid #ccc;
  padding: 8px 20px;
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  gap: 16px;
  white-space: nowrap;
`;

const FilterButton = styled(GhostButton)`
  height: auto;
  width: auto;
  padding: 8px;
`;

const ButtonList = styled.div`
  display: flex;
  flex-direction: column;
  padding: 16px 30px;
  gap: 5px;
`;

const ColumnButton = styled(Clickable)`
  width: 100%;
  text-transform: none;
  font-size: small;
  font-weight: 500;
  justify-content: space-between;
  border-radius: 4px;
  padding: 8px 12px;
  border: 1px solid ${({ theme }) => theme.grey};
  &:hover {
    background-color: ${({ theme }) => theme.primaryLight + 50};
  }
`;

interface ColumnFilterModalProps {
  allColumns: string[];
  hideableColumns: string[];
  selectedFilters: string[];
  toggleFilterTag: (status: string) => void;
}

export function ColumnFilterModal({
  allColumns,
  hideableColumns,
  selectedFilters,
  toggleFilterTag
}: ColumnFilterModalProps) {
  const handleClick = useCallback(
    (column: string) => {
      toggleFilterTag(column);
    },
    [toggleFilterTag]
  );

  return (
    <ModalWrapper>
      <ModalHeader>
        Display Columns
        <FilterButton onClick={() => toggleFilterTag("ALL")}>
          {selectedFilters.length < hideableColumns.length ? "Show All" : "Hide All"}
        </FilterButton>
      </ModalHeader>
      <ButtonList>
        {allColumns.map(column => (
          <ColumnButton
            key={column}
            onClick={() => handleClick(column)}
            disabled={!hideableColumns.includes(column)}
          >
            {column}
            <input
              type="checkbox"
              checked={selectedFilters.includes(column) || !hideableColumns.includes(column)}
              readOnly
            />
          </ColumnButton>
        ))}
      </ButtonList>
    </ModalWrapper>
  );
}
