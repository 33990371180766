import { lazy } from "react";
import { Route, Routes } from "react-router-dom";

import { Licenses } from "./About/Licenses";
import { LicensesView } from "./About/LicensesView";
import { LicensesWeb } from "./About/LicensesWeb";
import { ErrorReviewPage } from "./Error/ErrorPage";

const AboutPage = lazy(() => import("./About/AboutPage"));
const UserSettings = lazy(() => import("./UserSettings/UserSettings"));

export function GeneralPagesRoutes() {
  return (
    <Routes>
      <Route path="*" element={<AboutUserSettingsRoutes />} />
      <Route path="error" element={<ErrorReviewPage />} />
    </Routes>
  );
}

export function AboutUserSettingsRoutes() {
  return (
    <Routes>
      <Route path="about">
        <Route index element={<AboutPage />} />
        <Route path="licenses" element={<LicensesView />}>
          <Route path="web" element={<LicensesWeb />} />
          <Route path=":component" element={<Licenses />} />
        </Route>
      </Route>
      <Route path="user/settings" element={<UserSettings />} />
    </Routes>
  );
}
