import styled from "styled-components";

import { Loading } from "./Loading";

const LoadingFixed = styled.div`
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
`;

interface LoadingPageProps {
  customText?: string;
}

export function LoadingPage({ customText }: LoadingPageProps) {
  return (
    <LoadingFixed>
      <Loading customText={customText} />
    </LoadingFixed>
  );
}
