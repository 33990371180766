import { Navigate, Outlet } from "react-router-dom";

import { LoadingPage } from "@src/Components/Loading/LoadingPage";

import { useFetchNetworks } from "./networks";
import { NoNetworksPage } from "./NoNetwork";

export function InitialNetworkRedirect() {
  const { networks, loading } = useFetchNetworks();

  if (loading) return <LoadingPage />;
  if (networks.length === 0) return <NoNetworksPage />;
  return <Navigate to={`${networks[0].uid}`} />;
}

export function DefaultProtections() {
  const { networks, loading } = useFetchNetworks();

  if (loading) return <LoadingPage />;
  if (networks.length === 0) return <NoNetworksPage />;
  return <Outlet />;
}
