import { HTMLAttributes, TdHTMLAttributes, ThHTMLAttributes, useMemo } from "react";
import {
  Cell,
  CellProps,
  Column,
  HeaderGroup,
  Row,
  useExpanded,
  useGlobalFilter,
  usePagination,
  useRowSelect,
  useSortBy,
  useTable
} from "react-table";
import styled, { IStyledComponent } from "styled-components";

import { Clickable } from "../Buttons/Clickable";
import { Table, Td, Th, THead, Tr } from "./Table";

export const Wrapper = styled.div<{ $tableWidth?: string; $tableMargin?: string }>`
  width: ${({ $tableWidth }) => ($tableWidth ? $tableWidth : "100%")};
  margin: ${({ $tableMargin }) => ($tableMargin ? $tableMargin : "30px auto")};
`;

export const TableTh = styled(Th)`
  &:first-child,
  &:last-child {
    padding-left: 25px;
  }
`;

export const EmptyTable = styled(Td)`
  text-align: center;
  font-style: italic;
`;

const Pagination = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export type TableActions<T> = T & {
  _actions: "_actions";
  _actionsR: "_actionsR";
  _actionsL: "_actionsL";
};
export type ActionsCellProps<T extends object> = CellProps<TableActions<T>>;

interface TableComponentProps<D extends object> {
  items: D[];
  columnHeaders: Array<Column<D>>;
  leftActions?: Column<TableActions<D>>;
  rightActions?: Column<TableActions<D>>;
  pagination?: boolean;
  pageSize?: number;
  tableWidth?: string;
  tableMargin?: string;
  hiddenColumns?: string[];
  sortArray?: { id: string; desc: boolean }[];
  disableSort?: boolean;
  StyledTr?: IStyledComponent<"web", HTMLAttributes<HTMLTableRowElement>>;
  StyledTd?: IStyledComponent<"web", TdHTMLAttributes<HTMLTableCellElement>>;
  StyledTh?: IStyledComponent<"web", ThHTMLAttributes<HTMLTableCellElement>>;
}

export function TableComponent<D extends object>({
  items,
  columnHeaders,
  leftActions = null,
  rightActions = null,
  pagination = false,
  pageSize = 10,
  tableWidth,
  tableMargin,
  hiddenColumns = [],
  sortArray = [
    { id: "displayName", desc: false },
    { id: "name", desc: false }
  ],
  disableSort = false,
  StyledTr = Tr,
  StyledTd = Td,
  StyledTh = TableTh
}: TableComponentProps<D>) {
  const data = useMemo(() => items, [items]);
  const columns: Array<Column<D>> = useMemo(() => {
    const rightActionsColumn: Array<Column<D>> = rightActions ? [rightActions as Column<D>] : [];
    const leftActionsColumn: Array<Column<D>> = leftActions ? [leftActions as Column<D>] : [];
    return [...leftActionsColumn, ...(columnHeaders as Column<D>[]), ...rightActionsColumn];
  }, [columnHeaders, rightActions, leftActions]);

  const tableProps = useTable<D>(
    {
      columns,
      data: data as D[],
      initialState: {
        pageSize,
        sortBy: disableSort ? [] : sortArray,
        hiddenColumns
      },
      autoResetSelectedRows: false,
      autoResetSortBy: false,
      autoResetGlobalFilter: false,
      autoResetExpanded: false
    },
    useGlobalFilter,
    useSortBy,
    useExpanded,
    usePagination,
    useRowSelect
  );

  const {
    headerGroups,
    page,
    rows,
    state,
    pageOptions,
    pageCount,
    getTableProps,
    getTableBodyProps,
    prepareRow,
    gotoPage,
    previousPage,
    nextPage,
    canPreviousPage,
    canNextPage
  } = tableProps;

  const totalColumnsLength = columnHeaders.length + (leftActions ? 1 : 0) + (rightActions ? 1 : 0);

  return (
    <Wrapper $tableWidth={tableWidth} $tableMargin={tableMargin}>
      <Table {...getTableProps()}>
        <colgroup>
          {columns.map((c, i) => (
            <col key={i} style={{ width: c.width }} />
          ))}
        </colgroup>
        {headerGroups.map((hg: HeaderGroup<D>) => (
          <THead key={hg.id} {...hg.getHeaderGroupProps()}>
            <tr>
              {hg.headers.map((column: HeaderGroup<D>) => (
                <StyledTh
                  key={column.id}
                  {...column.getHeaderProps(
                    disableSort ? undefined : column.getSortByToggleProps()
                  )}
                >
                  {column.render("Header")}
                  <span> {column.isSorted ? (column.isSortedDesc ? "↓" : "↑") : ""}</span>
                </StyledTh>
              ))}
            </tr>
          </THead>
        ))}
        <tbody {...getTableBodyProps()}>
          {(pagination ? page.length : rows.length) === 0 ? (
            <Tr>
              <EmptyTable colSpan={totalColumnsLength}>There are no elements to show</EmptyTable>
            </Tr>
          ) : (
            (pagination ? page : rows).map((row: Row<D>) => {
              prepareRow(row);
              return (
                <StyledTr key={row.id} {...row.getRowProps()}>
                  {row.cells.map((cell: Cell<D>) => {
                    const { column } = cell;
                    return (
                      <StyledTd key={column.id} {...cell.getCellProps()}>
                        {cell.render("Cell")}
                      </StyledTd>
                    );
                  })}
                </StyledTr>
              );
            })
          )}
        </tbody>
      </Table>
      {pagination && pageOptions.length > 1 && (
        <Pagination>
          <Clickable onClick={() => gotoPage(0)} disabled={!canPreviousPage}>
            {"<<"}
          </Clickable>
          <Clickable onClick={previousPage} disabled={!canPreviousPage}>
            {"<"}
          </Clickable>
          <p>
            Page {state.pageIndex + 1} of {pageOptions.length}
          </p>
          <Clickable onClick={nextPage} disabled={!canNextPage}>
            {">"}
          </Clickable>
          <Clickable onClick={() => gotoPage(pageCount - 1)} disabled={!canNextPage}>
            {">>"}
          </Clickable>
        </Pagination>
      )}
    </Wrapper>
  );
}
