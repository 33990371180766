import styled, { css } from "styled-components";

import DotIcon from "@img/dot.svg";

export const ScrollBarStyle = css`
  //firefox
  scrollbar-width: thin;
  scrollbar-color: #5a5a5a #e9e9e9;
  //others
  ::-webkit-scrollbar {
    width: 6px;
  }
  ::-webkit-scrollbar-track {
    background: #e9e9e9;
  }
  ::-webkit-scrollbar-thumb {
    background-color: #5a5a5a;
  }
`;

export const TreeWrapper = styled.div`
  line-height: 1.7;
  height: 100%;
  font-size: 16px;
  overflow: auto;
  ${ScrollBarStyle};
`;

export const Collapsible = styled.div<{ $isOpen: boolean }>`
  margin-left: 13px;
  height: ${({ $isOpen }) => ($isOpen ? "auto" : 0)};
  overflow: hidden;
  border-left: 2px dotted #ccc;
`;

const SelectedStyledItem = css`
  background-color: ${({ theme }) => theme.backgroundLight};
  color: ${({ theme }) => theme.primary};
`;

export const TreeItem = styled.div<{ $selected?: boolean }>`
  display: flex;
  gap: 2px;
  position: relative;
  cursor: pointer;
  border-radius: 5px;
  ${({ $selected }) => ($selected ? SelectedStyledItem : null)}

  &:hover {
    background-color: ${({ $selected }) => ($selected ? "#f0f0f0" : "#f9f9f9")};
  }
`;

export const TreeDot = styled(DotIcon)`
  height: auto;
  width: 10px;
  padding: 0 10px;
`;
