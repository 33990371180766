import { useCallback, useEffect, useRef, useState } from "react";
import { NavigateFunction } from "react-router-dom";

export type ExpandedObject = { [siteId: string]: boolean };

export type SiteTreeState = {
  selected: string;
  expanded: ExpandedObject;
};
export interface NavigateSiteItem {
  siteId?: string;
}

export interface NavigationParams {
  navigate: NavigateFunction;
  currentPath: string;
}

interface SiteTreeParams {
  nav?: NavigationParams;
  ancestors?: string[];
  selectedSite?: string;
}

export function useSiteTree({ ancestors, selectedSite, nav }: SiteTreeParams): SiteTreeProps {
  const [state, setState] = useState<SiteTreeState>({
    selected: null,
    expanded: {}
  });
  const hasExpanded = useRef(false);
  useEffect(() => {
    if (hasExpanded.current || !ancestors) return;

    hasExpanded.current = true;
    setState(prevState =>
      ancestors.reduce(
        (state, id) => ({
          ...state,
          selected: selectedSite || id,
          expanded: { ...state.expanded, [id]: true }
        }),
        prevState
      )
    );
  }, [ancestors, selectedSite]);

  const collapseAll = useCallback(
    () =>
      setState(prevState => ({
        ...prevState,
        expanded: {}
      })),
    [setState]
  );

  const expandTree = useCallback(
    (id: string, ancestors: string[]) => {
      setState(prevState => ({
        ...prevState,
        selected: id,
        expanded: ancestors.reduce(
          (prev, ancestor) => ({
            ...prev,
            [ancestor]: true
          }),
          { ...prevState.expanded, [id]: true }
        )
      }));
    },
    [setState]
  );

  const selectTreeItem = useCallback(
    (id: string, ancestors: string[], navigateTo: NavigateSiteItem) => {
      expandTree(id, ancestors);

      if (!nav) return;
      const { navigate } = nav;
      const currentTab = getCurrentTab(nav.currentPath);
      const { siteId } = navigateTo;
      if (siteId) navigate(`site/${siteId}/${currentTab}`);
      else navigate(currentTab);
    },
    [expandTree, nav]
  );

  const toggleSite = useCallback((id: string) => {
    setState(prevState => ({
      ...prevState,
      expanded: { ...prevState.expanded, [id]: !prevState.expanded[id] }
    }));
  }, []);

  const selectSitePage = useCallback(
    (siteId: string, expanded: ExpandedObject, subPath: string) => {
      setState(prevState => ({
        ...prevState,
        selected: siteId,
        expanded: { ...expanded, [prevState.selected]: true }
      }));

      if (!nav) return;
      const { navigate } = nav;
      navigate(`site/${siteId}/${subPath}`);
    },
    [nav, setState]
  );

  return {
    state,
    collapseAll,
    selectTreeItem,
    toggleSite,
    selectSitePage,
    expandTree
  };
}

export interface SiteTreeProps {
  state: SiteTreeState;
  collapseAll: () => void;
  selectTreeItem: (id: string, ancestors: string[], navigateTo: NavigateSiteItem) => void;
  toggleSite: (id: string) => void;
  selectSitePage: (
    siteId: string,
    expanded: ExpandedObject,
    subPath: string,
    orgId: string
  ) => void;
  expandTree: (id: string, ancestors: string[]) => void;
}

export function getCurrentTab(currentPath: string) {
  const matches = currentPath.match(/(?:infrastructure|mec-sites)\/(?:site\/[^\/]+\/)?([^\/]+)/);

  return matches ? matches[1] : "";
}
