import { Outlet } from "react-router-dom";

import { ApolloProvider as Provider } from "@apollo/client";
import { getApolloClient } from "@src/Clients/Apollo/apolloClient";
import { useOrgCtx } from "@src/Hooks/Context/orgCtx";

export function ApolloProviderWithOrgCtx() {
  const orgCtx = useOrgCtx();
  const apolloClient = getApolloClient(orgCtx);

  return (
    <Provider client={apolloClient}>
      <Outlet />
    </Provider>
  );
}
