import { DefaultTheme } from "styled-components";

export const theme: DefaultTheme = {
  yellow: "rgb(255, 187, 60)",
  orange: "rgb(255, 99, 21)",
  red: "rgb(242, 39, 55)",
  darkblue: "rgb(25, 33, 108)",
  blue: "rgb(0, 69, 173)",
  lightblue: "rgb(0, 160, 223)",
  text: "rgb(29, 35, 42)",
  primary: "rgb(72, 72, 134)",
  primaryLight: "rgb(134, 138, 177)",
  error: "rgb(224, 92, 49)",
  warning: "rgb(237, 187, 79)",
  success: "rgb(123, 194, 155)",
  green: "rgb(63 179 115)",
  backgroundLight: "rgb(240, 240, 240)",
  transparent: "rgba(0, 0, 0, 0)",
  border: "rgb(221, 221, 221)",
  purple: "rgb(68, 75, 136)",
  lightPurple: "rgb(147, 60, 187)",
  darkPurple: "rgb(118, 25, 142)",
  grey: "rgb(204, 204, 204)",
  darkGrey: "rgb(125, 125, 125)",
  darkerGrey: "rgb(100, 100, 100)",
  failure: "rgb(231, 94, 49)",
  status: {
    running: "rgb(120, 164, 235)"
  }
};
