import { css } from "styled-components";

export const buttonBorderRadius = "3px";

export const baseButtonStyles = css`
  width: 200px;
  height: 45px;
  border: none;
  border-radius: ${buttonBorderRadius};
  letter-spacing: 0.5px;
  cursor: pointer;

  &:disabled {
    cursor: not-allowed;
  }
`;
