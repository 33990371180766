import { ReactNode } from "react";
import { NavLink, NavLinkProps } from "react-router-dom";
import styled from "styled-components";

export interface DisableableNavLinkProps extends NavLinkProps {
  disabled?: boolean;
  className?: string;
  children?: ReactNode;
}

const DisabledNavLink = styled.button`
  background: none;
  border: none;
  font: inherit;
  outline: inherit;
  cursor: not-allowed;
  padding: 0;
`;

export function DisableableNavLink({ disabled, ...props }: DisableableNavLinkProps) {
  return disabled ? (
    <DisabledNavLink disabled className={props.className}>
      {props.children}
    </DisabledNavLink>
  ) : (
    <NavLink {...props} />
  );
}
