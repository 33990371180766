import { FormikErrors } from "formik";
import { FormEvent } from "react";
import styled from "styled-components";

import { PrimaryTextButton } from "@src/Components/Buttons/Text";
import { InfoIcon } from "@src/Components/InfoIcon";
import { baseInputStyles } from "@src/Components/Input/Input";
import { CheckboxField, InputField, InputFieldWrapper } from "@src/Components/Input/InputGroup";
import { PositionInput, PositionInputValue } from "@src/Components/Input/PositionInput";
import { SiteSelectorInput } from "@src/Components/Input/SiteSelectorInput";
import { CoreDropdownInput } from "@src/MPNDashboard/Components/CoreDropdownInput";

import { ConfigField, ValueType } from "./initFormConfig";

const validateNumbers = (currentValue: number, type: string) => {
  if (type === "integer" && currentValue % 1 !== 0) {
    return "field must be a non floating point number";
  }
};

export type FieldInfo = Omit<ConfigField, "label">;

interface RenderedInputProps<E> {
  name: string;
  fieldInfo: FieldInfo;
  index: number;
  errors: FormikErrors<E>;
  setFieldValue?: (field: string, value: ValueType) => void;
}

export function RenderedInput<E>({
  name,
  fieldInfo,
  index,
  errors,
  setFieldValue
}: RenderedInputProps<E>) {
  const { type, value, initialValue, selected } = fieldInfo;

  switch (type) {
    case "integer":
    case "number":
      return (
        <InputField
          name={`${name}.${index}.value`}
          placeholder={+initialValue || undefined}
          errors={errors}
          type="number"
          autocomplete="off"
          validate={(currentValue: number) => validateNumbers(currentValue, type)}
          min={0}
        />
      );
    case "boolean":
      return (
        <CheckboxField checked={value as boolean} name={`${name}.${index}.value`} errors={errors} />
      );
    case "site-selector":
      return (
        <SiteSelectorInput
          modalTitle="Select an infrastructure site where to run the managers for this network"
          setFieldValue={setFieldValue}
          name={`${name}.${index}.value`}
          siteId={value as string}
          errors={errors}
        />
      );
    case "core-selector":
      return (
        <CoreDropdownInput
          setFieldValue={setFieldValue}
          name={`${name}.${index}.value`}
          coreId={value as string}
          errors={errors}
        />
      );
    case "location-selector":
      return (
        <PositionInput
          value={value as PositionInputValue}
          setFieldValue={setFieldValue}
          name={`${name}.${index}.value`}
          errors={errors}
        />
      );
    default:
      const warning =
        value === "" && selected ? "This will replace the value with an empty string." : null;
      return (
        <InputField
          type="text"
          name={`${name}.${index}.value`}
          placeholder={(initialValue as string) || undefined}
          errors={errors}
          warning={warning}
        />
      );
  }
}

export const RedactedWrapper = styled(InputFieldWrapper)`
  display: flex;
  align-items: center;
  margin-bottom: 36px;
  ${baseInputStyles};
  border: none;
  white-space: nowrap;
`;

const I = styled(InfoIcon)`
  margin-left: -2px;
`;

interface RedactedFieldProps {
  openField: (e: MouseEvent | FormEvent) => void;
}
export function RedactedField({ openField }: RedactedFieldProps) {
  return (
    <RedactedWrapper>
      <I>
        This field contains sensitive info.{" "}
        <PrimaryTextButton type="button" onClick={openField}>
          Click here to modify it.
        </PrimaryTextButton>
      </I>
    </RedactedWrapper>
  );
}
