import axios from "axios";

const axiosInstance = axios.create();

axiosInstance.interceptors.response.use(function (response) {
  // response does not have data to parse through
  if (!response?.data) return response;

  // invalid content-type, we respond with null data
  // if (!response.headers["content-type"].includes("application/json"))return { ...response, data: null };

  if (!response.headers["content-type"].includes("application/json")) {
    return { ...response, data: null };
  }

  return response;
});

export default axiosInstance;
