import { CellProps, Column } from "react-table";
import styled from "styled-components";

import { Td, Th, Tr } from "@src/Components/Table/Table";
import { TableComponent } from "@src/Components/Table/TableComponent";
import { TableSkeleton } from "@src/Components/Table/TableSkeleton";
import { AuditLogNode } from "@src/SharedViews/Logs/fetchLogs";
import { UnresolvedField } from "@src/SharedViews/Logs/ReportLogs";

const StyledTr = styled(Tr)`
  height: 38px;
  &:nth-child(even) {
    background-color: #f0f0f0;
  }
  &:nth-child(odd) {
    background-color: white;
  }
`;
const StyledTd = styled(Td)`
  font-size: 12px;
  letter-spacing: initial;
  font-weight: 500;
  padding-left: 10px;
  background: inherit;
  &:first-child {
    padding-left: 16px;
  }
  &:last-child {
    padding-right: 16px;
  }
`;
const StyledTh = styled(Th)`
  padding-left: 10px;
`;

const Message = styled.span`
  display: block;
  width: 100%;
  max-width: 35vw;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

interface LogColumn extends AuditLogNode {
  entityName: string;
}

interface PanelLogsTableProps {
  logs: AuditLogNode[];
  loading: boolean;
}

export function PanelLogsTable({ logs, loading }: PanelLogsTableProps) {
  const columns: Array<Column<LogColumn>> = [
    {
      Header: "Time",
      accessor: "time",
      width: "15%",
      Cell: ({ value }: CellProps<LogColumn, LogColumn["time"]>) => {
        return value || <UnresolvedField>Time not available</UnresolvedField>;
      }
    },
    {
      Header: "Entity Type",
      accessor: "entityType",
      width: "7.5%"
    },
    {
      id: "Entity Name",
      Header: "Entity Name",
      accessor: "entity",
      width: "10%",
      Cell: ({ value }: CellProps<LogColumn, LogColumn["entity"]>) => {
        const entity = value;
        const entityName =
          entity?.__typename === "BlockChart" ? entity?.blockChartName : entity?.name;
        return entityName || <UnresolvedField>Name not available</UnresolvedField>;
      }
    },
    {
      Header: "Organization",
      accessor: "org",
      width: "12.5%",
      Cell: ({ value }: CellProps<LogColumn, LogColumn["org"]>) => {
        const orgName = value?.displayName;
        return orgName || <UnresolvedField>Org not available</UnresolvedField>;
      }
    },
    {
      Header: "User",
      accessor: "user",
      width: "12.5%",
      Cell: ({ value }: CellProps<LogColumn, LogColumn["user"]>) => {
        const email = value?.email;
        return email || <UnresolvedField>Email not available</UnresolvedField>;
      }
    },
    {
      Header: "Type",
      accessor: "type",
      width: "7.5%"
    },
    {
      Header: "Reason",
      accessor: "reason",
      width: "10%",
      Cell: ({ value }: CellProps<LogColumn, LogColumn["reason"]>) => {
        return value || <UnresolvedField>Reason not available</UnresolvedField>;
      }
    },
    {
      Header: "Message",
      accessor: "message",
      width: "25%",
      Cell: ({ value }: CellProps<LogColumn, LogColumn["message"]>) => {
        return value ? (
          <Message>{value}</Message>
        ) : (
          <UnresolvedField>Message not available</UnresolvedField>
        );
      }
    }
  ];

  const tableItems: LogColumn[] = logs.map(log => ({ ...log, entityName: "" }));

  return loading ? (
    <TableSkeleton
      columns={[...columns.map(({ width }) => width)]}
      rowNumber={8}
      StyledTr={StyledTr}
      StyledTd={StyledTd}
    />
  ) : (
    <TableComponent<LogColumn>
      columnHeaders={columns}
      items={tableItems}
      tableMargin="0 auto 20px auto"
      StyledTr={StyledTr}
      StyledTd={StyledTd}
      StyledTh={StyledTh}
      disableSort
    />
  );
}
