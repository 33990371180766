import { useCallback } from "react";
import styled from "styled-components";

import Calendar from "@img/calendar.svg";
import { Clickable } from "@src/Components/Buttons/Clickable";
import { PrimaryButton } from "@src/Components/Buttons/Primary";
import { baseInputStyles } from "@src/Components/Input/Input";

import { dateToFormattedString } from "./dateUtils";
import { SectionTitle } from "./QuickRangeList";

const RangeSelectorsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 10px 30px;
  gap: 10px;
`;

const ApplyRangeButton = styled(PrimaryButton)`
  height: initial;
  width: initial;
  margin-top: 10px;
  padding: 10px 15px;
  align-self: flex-end;
`;

interface RangeSelectorsProps {
  openCalendar: () => void;
  applyRange: (range: [Date, Date]) => void;
  calendarState: { range: [Date, Date]; touched: boolean };
}

export function RangeSelectors({ openCalendar, applyRange, calendarState }: RangeSelectorsProps) {
  const submitRange = useCallback(() => {
    applyRange(calendarState.range);
  }, [applyRange, calendarState.range]);

  return (
    <div>
      <SectionTitle>Select a time range</SectionTitle>
      <RangeSelectorsWrapper>
        <CalendarField label="From" openCalendar={openCalendar} value={calendarState.range[0]} />
        <CalendarField label="To" openCalendar={openCalendar} value={calendarState.range[1]} />
        <ApplyRangeButton disabled={!calendarState.touched} onClick={submitRange}>
          Apply Range
        </ApplyRangeButton>
      </RangeSelectorsWrapper>
    </div>
  );
}

const CalendarInputButton = styled(Clickable)`
  ${baseInputStyles}
  display: flex;
  margin-top: 3px;
  height: auto;
  width: min-content;
  padding: 6px 10px;
  gap: 10px;
  justify-content: space-between;
`;

const CalendarIcon = styled(Calendar)`
  height: 22px;
  width: 22px;
  & path {
    fill: ${({ theme }) => theme.primary};
  }
`;

const InputLabel = styled.label`
  font-weight: 600;
`;

const Date = styled.span`
  white-space: nowrap;
`;

interface CalendarFieldProps {
  openCalendar: () => void;
  label: string;
  value: Date;
}

function CalendarField({ openCalendar, label, value }: CalendarFieldProps) {
  const formattedDate = dateToFormattedString(value.getTime());
  return (
    <div>
      <InputLabel>{label}</InputLabel>
      <CalendarInputButton onClick={openCalendar}>
        <Date> {formattedDate} </Date>
        <CalendarIcon />
      </CalendarInputButton>
    </div>
  );
}
