import { useCallback } from "react";
import { Outlet, useNavigate } from "react-router-dom";

import { GhostButton } from "@src/Components/Buttons/Ghost";
import { H1 } from "@src/Components/Text";
import { TopLine } from "@src/Components/TopLine";

export function LicensesView() {
  const navigate = useNavigate();
  const goBack = useCallback(() => navigate(-1), [navigate]);

  return (
    <>
      <TopLine>
        <H1>Licenses</H1>
        <GhostButton onClick={goBack}>back</GhostButton>
      </TopLine>
      <Outlet />
    </>
  );
}
