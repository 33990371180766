import styled, { css } from "styled-components";

import { tabContentPadding } from "./StyleConstants/padding";

const tabContentStyles = css`
  position: relative;
  border: 1px solid #dddddd;
`;

export const TabContent = styled.div`
  ${tabContentStyles}
  padding: 0 ${tabContentPadding} ${tabContentPadding};
`;

export const TabContentFullPadding = styled.div`
  ${tabContentStyles}
  padding: ${tabContentPadding};
`;
