import { Namespace } from "@src/Generated/permissions/namespace";
import { Permit } from "@src/Generated/permissions/permit";

import { usePermissionCheck } from "./permissionsCheck";

export function useServicesDashboard() {
  const { allowed, loading } = usePermissionCheck({
    relation: Permit.View,
    objectType: Namespace.Dashboard,
    objectId: "services"
  });

  return { allowed, loading };
}
