import styled from "styled-components";

import { useLogoutHandler } from "@src/Auth/Logout";
import { DangerButton } from "@src/Components/Buttons/Danger";
import { H1, H3 } from "@src/Components/Text";

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
  padding: 30px 25%;
  gap: 30px;

  & button {
    align-self: flex-end;
  }
`;
export function NoDashboard() {
  const logout = useLogoutHandler();

  return (
    <Wrapper>
      <H1>No Dashboard available for the current user</H1>
      <H3>Contact your System Admin to be granted permissions.</H3>
      <DangerButton onClick={logout}>Sign Out</DangerButton>
    </Wrapper>
  );
}
