import { Namespace } from "@src/Generated/permissions/namespace";
import { Permit } from "@src/Generated/permissions/permit";

import { usePermissionCheck } from "../permissionsCheck";

export function useCanCreateMECChain(orgId: string) {
  return usePermissionCheck({
    objectType: Namespace.Org,
    objectId: orgId,
    relation: Permit.CreateMecChain
  });
}

export function useCanEditMECChain(id: string) {
  return usePermissionCheck({
    objectType: Namespace.MecChain,
    objectId: id,
    relation: Permit.Write
  });
}

export function useCanDeleteMECChain(id: string) {
  return usePermissionCheck({
    objectType: Namespace.MecChain,
    objectId: id,
    relation: Permit.Delete
  });
}
