import { useMemo } from "react";
import { useQuery as useReactQuery, UseQueryOptions } from "react-query";

import axios from "@src/Clients/ReactQuery/axiosInstance";
import { ChartConfigField } from "@src/Generated/graphql";
import { useNetworkCtx } from "@src/Hooks/Context/networkCtx";
import { useOrgCtx } from "@src/Hooks/Context/orgCtx";

import { headerNetworksKey, mpnRequestUrl, QueryCallbacks } from "./mpnRequests";

interface NetworkField {
  name: string;
  value: string | number;
}
export interface NetworkResponse {
  resourceCharacteristic: NetworkField[];
}

export interface SerialisedNetwork {
  displayname: string;
  uid: string;
  cores: number;
  readycores: number;
  addNetwork?: boolean;
  gnbs: number;
  upgnbs: number;
  sims: number;
  connectedsims: number;
  pendingsims: number;
  unknownsims: number;
  deregisteredsims: number;
  registeredsims: number;
  disconnectedsims: number;
  inactivesims: number;
  criticalalarms: number;
  warningalarms: number;
  notificationalarms: number;
  upstreambytespersecond: number;
  downstreambytespersecond: number;
}

export function fetchNetworks(
  orgId: string,
  callbacks?: QueryCallbacks
): Promise<NetworkResponse[]> {
  const params = new URLSearchParams({
    filter: `$[?(@.relatedParty[?(@.@referredType=="Organization" && @.id=="${orgId}")])]`
  });

  return axios
    .get(`/mobile-networks/network/tmf-api/resourceInventoryManagement/v4/resource?${params}`)
    .then(response => {
      const data = response?.data;
      if (!callbacks) return data;

      callbacks.onComplete?.(data);
      return data;
    });
}

export function fetchNetwork(networkId: string): Promise<NetworkResponse> {
  const url = mpnRequestUrl({
    path: `/mobile-networks/network/tmf-api/resourceInventoryManagement/v4/resource/${networkId}`
  });
  return axios.get(url.href).then(response => response?.data);
}

export function useFetchNetworks(
  keys = [headerNetworksKey],
  options?: UseQueryOptions<NetworkResponse[]>,
  callbacks?: QueryCallbacks<NetworkResponse[]>
) {
  const orgCtx = useOrgCtx();
  const { data, isLoading: loading } = useReactQuery(
    [...keys, orgCtx],
    () => fetchNetworks(orgCtx, callbacks),
    {
      refetchOnWindowFocus: false,
      ...options
    }
  );
  const networkData = useMemo(
    () =>
      (serialiseNetworksData(data) as SerialisedNetwork[]).sort((netA, netB) =>
        netA.displayname.localeCompare(netB.displayname)
      ),
    [data]
  );

  return { networks: networkData || [], loading };
}

export function useSelectedNetwork() {
  const networkCtx = useNetworkCtx();
  const { networks, loading } = useFetchNetworks();

  return {
    network: networks.find(network => network.uid === networkCtx),
    loading
  };
}

export function serialiseNetworksData(networkResponse: NetworkResponse[]) {
  const networkFields = (networkResponse || []).map(el => el?.resourceCharacteristic);
  return networkFields.map(arr => {
    const network = arr.reduce((acc, el) => {
      const key = el.name.toLowerCase();
      return {
        ...acc,
        [key]: el.value
      };
    }, {});
    return network;
  });
}

interface NetworkDetails extends Pick<SerialisedNetwork, "uid" | "displayname"> {
  chartname: string;
  chartversion: string;
  charttype: string;
  config: ChartConfigField[];
}

interface NetworkConfigFields {
  customerName: string;
  customerId: string;
  description: string;
  infraSiteId: string;
  region: string;
  sector: string;
}

export function useFetchNetworkDetails() {
  const orgCtx = useOrgCtx();
  const networkCtx = useNetworkCtx();

  const { data, isLoading: loading } = useReactQuery(
    [orgCtx, networkCtx, "network-details"],
    () => fetchNetwork(networkCtx),
    {
      refetchOnWindowFocus: false,
      cacheTime: 0
    }
  );
  const networkData = useMemo(() => {
    if (!data) return null;
    return serialiseNetworksData([data])[0];
  }, [data]) as NetworkDetails;

  return { network: networkData, loading };
}

export function serialiseNetworkConfigDetails(config: ChartConfigField[]) {
  return config.reduce((acc, field) => {
    return {
      ...acc,
      [field.label]: field.value
    };
  }, {}) as NetworkConfigFields;
}
