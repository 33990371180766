import SkeletonComponent, { SkeletonProps } from "react-loading-skeleton";
import styled, { keyframes } from "styled-components";

const skeletonLoading = keyframes`
  0% {
      border: 6px solid #eeeeee;
    }
    100% {
      border: 6px solid #cccccc;
    }
`;

export const SkeletonBorder = styled.div<{ $height?: string }>`
  height: ${({ $height }) => ($height ? $height : "100%")};
  width: 100%;
  animation: ${skeletonLoading} 1s linear infinite alternate;
  box-sizing: border-box;
`;

export function Skeleton(props: SkeletonProps) {
  const style = { ...props.style, zIndex: 0 };
  return <SkeletonComponent {...props} style={style} />;
}
