import { Namespace } from "@src/Generated/permissions/namespace";
import { Permit } from "@src/Generated/permissions/permit";

import { PermissionArgs, usePermissionCheck } from "../permissionsCheck";

export const viewFederationPerms: (orgId: string) => PermissionArgs = orgId => ({
  objectType: Namespace.Org,
  objectId: orgId,
  relation: Permit.ViewFederation
});

export function useCanViewFederation(orgId: string) {
  return usePermissionCheck(viewFederationPerms(orgId));
}
