import { NavLink } from "react-router-dom";
import styled from "styled-components";

import { useGetDashboardBaseUrl } from "@src/Hooks/useCurrentDashboard";

import { Clickable } from "./Buttons/Clickable";

const ToastWrapper = styled.div`
  font-size: 16px;
  font-weight: 500;
`;
const ToastTitle = styled.h4`
  margin: 4px 0;
`;
const ToastMsg = styled.p`
  margin: 4px 0;
`;

type ToastProps = {
  title: string;
  msg?: string;
  onClick?: () => void;
  children?: React.ReactNode;
};

export function ToastNotification({ title, msg, onClick, children }: ToastProps) {
  return (
    <Clickable onClick={onClick}>
      <ToastWrapper>
        <ToastTitle>{title}</ToastTitle>
        <ToastMsg>{msg && msg}</ToastMsg>
        {children}
      </ToastWrapper>
    </Clickable>
  );
}

type ToastLongErrorProps = {
  navUrl: string;
};

export function ToastLongError({ navUrl }: ToastLongErrorProps) {
  const baseDashboardUrl = useGetDashboardBaseUrl();
  return (
    <>
      <ToastMsg>Error is to long to be shown in this toast.</ToastMsg>
      <NavLink to={baseDashboardUrl + navUrl}>Click here to read the whole error.</NavLink>
    </>
  );
}
