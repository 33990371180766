import { Fragment, useEffect, useState } from "react";
import { Navigate, useSearchParams } from "react-router-dom";
import styled from "styled-components";

import { FlowError } from "@ory/client";
import { DescriptionList } from "@src/Components/Details/DescriptionList";
import { Title } from "@src/Components/Modal/Modal";

import { fetchErrors } from "./kratos";

const Dl = styled(DescriptionList)`
  text-align: left;
`;

export function Error() {
  const [requestResponse, setRequestResponse] = useState<FlowError>();
  const [endTimeOut, setEndTimeout] = useState(false);
  const [searchParams] = useSearchParams();

  useEffect(() => {
    const error = searchParams.get("id");

    if (error) {
      fetchErrors(error).then(errs => {
        if (errs) {
          setRequestResponse(errs);
        }
      });
    }
  }, [searchParams]);

  // match Kratos Jsonnet error, which looks like
  // jsonnetsecure: ERROR: RUNTIME ERROR: user is not a member of any groups
  //   file:///etc/kratos/jsonnet/post-oidc-login.jsonnet:4:5-47	function <anonymous>
  //   Top-level function call
  const errorMsg = (requestResponse?.error as { message: string })?.message;
  const isNoGroupsError = /user is not a member of any groups/.test(errorMsg);

  useEffect(() => {
    if (isNoGroupsError) return;
    const timer = setTimeout(() => {
      setEndTimeout(true);
    }, 3000);

    return () => clearTimeout(timer);
  }, [isNoGroupsError, searchParams]);

  return (
    <>
      {endTimeOut ? (
        <Navigate to="/" />
      ) : (
        <>
          <Title>Error</Title>
          {isNoGroupsError ? (
            <>
              <Dl>
                <dt>status</dt>
                <dd>Unauthorized</dd>
                <dt>error</dt>
                <dd>user is not a member of any groups</dd>
              </Dl>
              <p>
                Click <a href="/">here</a> to return to home page
              </p>
            </>
          ) : (
            <>
              <Dl>
                {Object.entries(requestResponse?.error || {}).map(([key, value]) => (
                  <Fragment key={key}>
                    <dt>{key}</dt>
                    <dd>{value}</dd>
                  </Fragment>
                ))}
              </Dl>
              <p>
                Redirecting to home page, click <a href="/">here</a> if you are not automatically
                redirected
              </p>
            </>
          )}
        </>
      )}
    </>
  );
}
