import { Namespace } from "@src/Generated/permissions/namespace";
import { Permit } from "@src/Generated/permissions/permit";

import { usePermissionCheck } from "../permissionsCheck";

export function useMECDashboard() {
  const { allowed, loading } = usePermissionCheck({
    relation: Permit.View,
    objectType: Namespace.Dashboard,
    objectId: "mec"
  });

  return { allowed, loading };
}
