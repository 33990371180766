import { Navigate } from "react-router-dom";

import { useMPNDashboard } from "@src/Hooks/Permissions/mpnDashboard";
import { useServicesDashboard } from "@src/Hooks/servicesDashboard";

import { useLandingPage } from "./Hooks/Permissions/landingPage";
import { useMECDashboard } from "./Hooks/Permissions/mecDashboard";

export function DefaultPageRedirect() {
  const { allowed: allowServices } = useServicesDashboard();
  const { allowed: allowMPN } = useMPNDashboard();
  const { allowed: allowMEC } = useMECDashboard();
  const { allowed: showLanding } = useLandingPage();

  if (allowMPN == null || allowServices == null || allowMEC == null || allowMEC == null) {
    return null;
  }

  const landingPage = { url: "home", hasPermission: showLanding };
  const servicesDashboard = { url: "services/overview", hasPermission: allowServices };
  const mpnDashboard = { url: "mpn", hasPermission: allowMPN };
  const mecDashboard = { url: "mec", hasPermission: allowMPN };

  const dashboards = [landingPage, servicesDashboard, mpnDashboard, mecDashboard];

  const redirectPath = dashboards.find(d => d.hasPermission)?.url || "no-dashboard";

  return <Navigate to={redirectPath} />;
}
