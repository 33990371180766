import { Namespace } from "@src/Generated/permissions/namespace";
import { Permit } from "@src/Generated/permissions/permit";

import { usePermissionCheck } from "../permissionsCheck";

export function useCanReadAdminDashboard() {
  return usePermissionCheck({
    objectType: Namespace.AdminDashboard,
    objectId: NEARBY_ONE_ENV_ID,
    relation: Permit.Read
  });
}
