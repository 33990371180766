import AppDesignIcon from "@img/app-design.svg";
import TenantsIcon from "@img/groups.svg";
import MECSitesIcon from "@img/mec-sites.svg";
import ObserveIcon from "@img/observe.svg";
import OperateIcon from "@img/operate.svg";
import { NavIcons } from "@src/Components/Navigation/NavbarStyles";
import { NavItem } from "@src/Components/Navigation/NavItem";
import { useOrgCtx } from "@src/Hooks/Context/orgCtx";
import { useCanCreateMECChain } from "@src/Hooks/Permissions/mecChain";

export function MECNavbar() {
  const orgCtx = useOrgCtx();
  const rootPath = `/app/${orgCtx}/mec`;

  const { allowed: canCreateMECChain } = useCanCreateMECChain(orgCtx);

  return (
    <NavIcons>
      <NavItem linkTo={rootPath + "/operate"} label="Applications" icon={OperateIcon} />
      <NavItem
        linkTo={rootPath + "/app-designer"}
        label="App Designer"
        icon={AppDesignIcon}
        disabled={!canCreateMECChain}
      />
      <NavItem linkTo={rootPath + "/mec-sites"} label="MEC Sites" icon={MECSitesIcon} />
      <NavItem linkTo={rootPath + "/tenants"} label="Tenants" icon={TenantsIcon} />
      <NavItem linkTo={rootPath + "/observe"} label="Observe" icon={ObserveIcon} />
    </NavIcons>
  );
}
