import styled from "styled-components";

import { Tag } from "@src/Components/Tags";

const Enabled = styled(Tag)`
  background-color: ${({ theme }) => theme.success};
  color: white;
`;

const Disabled = styled(Tag)`
  background-color: ${({ theme }) => theme.failure};
  color: white;
`;

const Unknown = styled(Tag)`
  border: 1px solid ${({ theme }) => theme.grey};
  color: #ccc;
`;

export enum SIMStatus {
  Enabled = "Enabled",
  Disabled = "Disabled"
}

export const simStatus = Object.values(SIMStatus);

export function SIMStatusTag({ status }: { status: SIMStatus }) {
  switch (status) {
    case SIMStatus.Enabled:
      return <Enabled>enabled</Enabled>;
    case SIMStatus.Disabled:
      return <Disabled>disabled</Disabled>;
    default:
      return <Unknown>unknown</Unknown>;
  }
}

export enum SIMOPStatus {
  Pending = "Pending",
  Connected = "Connected",
  Unknown = "Unknown",
  Deregistered = "Deregistered",
  Disconnected = "Disconnected",
  Inactive = "Inactive",
  Registered = "Registered"
}

export const simOpPStatus = Object.values(SIMOPStatus);

const Connected = Enabled;

const Disconnected = Disabled;

const Registered = styled(Tag)`
  background-color: white;
  color: ${({ theme }) => theme.success};
  border: 1px solid ${({ theme }) => theme.success};
`;

const Inactive = styled(Tag)`
  background-color: white;
  color: ${({ theme }) => theme.failure};
  border: 1px solid ${({ theme }) => theme.failure};
`;

const Deregistered = Inactive;

const Pending = styled(Tag)`
  background-color: ${({ theme }) => theme.lightblue};
  color: white;
`;

interface SIMOPStatusTagProps {
  status: SIMOPStatus;
}

export function SIMOPStatusTag({ status }: SIMOPStatusTagProps) {
  switch (status) {
    case SIMOPStatus.Connected:
      return <Connected>connected</Connected>;
    case SIMOPStatus.Pending:
      return <Pending>pending</Pending>;
    case SIMOPStatus.Registered:
      return <Registered>registered</Registered>;
    case SIMOPStatus.Disconnected:
      return <Disconnected>Disconnected</Disconnected>;
    case SIMOPStatus.Inactive:
      return <Inactive>inactive</Inactive>;
    case SIMOPStatus.Deregistered:
      return <Deregistered>deregistered</Deregistered>;
    default:
      return <Unknown>unknown</Unknown>;
  }
}
