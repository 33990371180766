import { useCallback } from "react";
import toast from "react-hot-toast";
import { useLocation, useNavigate } from "react-router-dom";

import { ToastNotification } from "@src/Components/ToastNotification";

import { redirectOrg } from "./redirectOrg";

export function useSelectOrg() {
  const { pathname } = useLocation();
  const navigate = useNavigate();

  const selectOrg = useCallback(
    (org: string) => {
      navigate(redirectOrg(pathname, org));
      toast.success(<ToastNotification title={`Switched org`} />);
    },
    [navigate, pathname]
  );

  return {
    selectOrg
  };
}
