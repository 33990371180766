import { FormikErrors } from "formik";
import { useCallback } from "react";
import styled from "styled-components";

import ResetIcon from "@img/reset.svg";
import { Clickable } from "@src/Components/Buttons/Clickable";
import { SecondaryTextButton } from "@src/Components/Buttons/Text";
import { redactedStyles } from "@src/Components/Review/FieldReview";

import { compareValueState } from "./dynamicFormUtils";
import { ValueType } from "./initFormConfig";
import { FieldInfo, RedactedField, RedactedWrapper, RenderedInput } from "./RenderedInputs";

const InputWrapper = styled.span`
  position: relative;
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  input[type="number"] {
    appearance: textfield;
  }
`;

const CancelButton = styled(SecondaryTextButton).attrs({ type: "button" })`
  color: ${({ theme }) => theme.error};
  height: fit-content;
  width: fit-content;
  height: 45px;
  padding: 0px 20px;
  font-weight: 500;
  &:hover {
    color: ${({ theme }) => theme.error};
    text-decoration: underline;
  }
`;

const SelectionButton = styled(CancelButton)<{ $selected: boolean }>`
  color: ${({ theme, $selected }) => ($selected ? theme.lightblue : theme.primary)};
  &:hover {
    color: ${({ theme, $selected }) => ($selected ? theme.lightblue : theme.primary)};
  }
`;

const ResetButton = styled(Clickable)`
  position: absolute;
  height: 1.5em;
  top: 0;
  right: 5px;
  height: 45px;
  opacity: 0.2;
  &:hover {
    opacity: 0.6;
  }
`;
const Reset = styled(ResetIcon)`
  height: 1.2em;
`;
const UnsetField = styled(RedactedWrapper)`
  display: flex;
  justify-content: center;
  text-align: center;
  white-space: wrap;
  height: auto;
  word-break: break-all;
  ${redactedStyles};
`;

// We specify that the name property can't have "." in them because if would cause errors on the Formik functionality
export type NoDots<S extends string> = S extends `${string}.${string}` ? never : S;

export interface ConfigInputProps<N extends string, E> {
  name: NoDots<N>;
  fieldInfo: FieldInfo;
  index: number;
  errors: FormikErrors<E>;
  isEdit: boolean;
  setFieldValue: (field: string, value: ValueType) => void;
}
export function ConfigInput<N extends string, E>({
  name,
  fieldInfo,
  index,
  errors,
  isEdit,
  setFieldValue
}: ConfigInputProps<N, E>) {
  const { selected, initialValue, open, redacted, required } = fieldInfo;

  const toggleFieldOpen = useCallback(() => {
    setFieldValue(`${name}.${index}.open`, !open);
    setFieldValue(`${name}.${index}.selected`, !selected);
  }, [name, open, selected, index, setFieldValue]);

  const toggleFieldSelected = useCallback(() => {
    setFieldValue(`${name}.${index}.selected`, !selected);
  }, [name, selected, index, setFieldValue]);

  const resetFieldValue = useCallback(() => {
    setFieldValue(`${name}.${index}.value`, initialValue || "");
  }, [name, initialValue, index, setFieldValue]);

  if (!fieldInfo.open && isEdit) return <RedactedField openField={toggleFieldOpen} />;

  const showReset = isEdit && !compareValueState(fieldInfo);

  const shouldShowSkip = !required && (!isEdit || (isEdit && !redacted));

  const disabledValue =
    initialValue == null ? "unset" : initialValue === "" ? "❛❛ ❜❜" : initialValue;

  return (
    <>
      <InputWrapper>
        {selected ? (
          <>
            <RenderedInput
              name={name}
              fieldInfo={fieldInfo}
              errors={errors}
              index={index}
              setFieldValue={setFieldValue}
            />
            {showReset && (
              <ResetButton title="reset field" onClick={resetFieldValue}>
                <Reset />
              </ResetButton>
            )}
          </>
        ) : (
          <UnsetField>{typeof disabledValue === "object" ? "Unset" : disabledValue}</UnsetField>
        )}
      </InputWrapper>
      {shouldShowSkip && (
        <SelectionButton onClick={toggleFieldSelected} $selected={selected}>
          {selected ? "Skip" : "Set"}
        </SelectionButton>
      )}
      {redacted && isEdit && <CancelButton onClick={toggleFieldOpen}>Cancel</CancelButton>}
    </>
  );
}
