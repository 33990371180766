// GENERAL COMPONENTS
export const headerMenusZIndex = 1;
export const sidePanelZIndex = 2;
export const modalZIndex = 4;
export const dropdownMenuZIndex = 5;
export const loadingOverlayZIndex = 10; // GREATER THAN ALL

// BOTTOM PANEL
export const bottomPanelZIndex = 3;
export const panelTopZIndex = 1;

// EMBEDDED EDITOR
export const editConfigOptionsZIndex = 6;
export const readEditorReloadZIndex = 1;
