import { SIMStatus } from "./SimCards/Manage/SIMStatusTag";

export const headerNetworksKey = "header-network-fetch";

export const mpnRefetchInterval = 5000;

export interface QueryCallbacks<T = object> {
  onComplete?: (data?: T) => void;
  onError?: (error: Error) => void;
}

export interface MPNRequestParams {
  path: string;
  orgId?: string;
  networkId?: string;
  coreId?: string;
}

export function mpnRequestParams({ orgId, networkId, coreId }: Omit<MPNRequestParams, "path">) {
  const params = new URLSearchParams();

  if (orgId) {
    params.append("filter", `relatedParty[?(@.@referredType==Organization && @.id==${orgId})]`);
  }

  if (networkId) {
    params.append(
      "filter",
      `resourceRelationship[?(@.relationshipType==targets && @.resource.@referredType==Network && @.resource.id==${networkId})]`
    );
  }

  if (coreId) {
    params.append(
      "filter",
      `resourceRelationship[?(@.relationshipType==targets && @.resource.@referredType==Core && @.resource.id==${coreId})]`
    );
  }

  return params;
}

export function mpnRequestUrl(params: MPNRequestParams) {
  const { path } = params;
  const url = new URL(path, window.location.origin);
  url.search = mpnRequestParams(params).toString();
  return url;
}

export function mpnManagerRequestUrl({
  path,
  orgId,
  networkId,
  categoryId
}: {
  path: string;
  orgId: string;
  networkId: string;
  categoryId: string;
}) {
  const params = new URLSearchParams();

  params.append("category.id", categoryId);
  params.append(
    "filter",
    `$[?(@.relatedParty[?(@.@referredType=="Organization" && @.id=="${orgId}")])]`
  );

  params.append(
    "filter",
    `$[?(@.supportingResource[?(@.@referredType=="Network" && @.id=="${networkId}")])]`
  );

  return `${path}?${params}`;
}

export type SimRequestParams = {
  networkId: string;
  coreId: string;
  imsiFilter?: string;
  selectedFilters: SIMStatus[];
} & ({ page: string; size: string } | { page?: never; size?: never });

export function mpnSIMRequestParams({
  networkId,
  coreId,
  page,
  size,
  imsiFilter,
  selectedFilters
}: SimRequestParams) {
  const params = new URLSearchParams([
    [
      "filter",
      `$[?(@.resourceRelationship[?(@.relationshipType=="targets" && @.resource.@referredType=="Network" && @.resource.id=="${networkId}")])]`
    ],
    [
      "filter",
      `$[?(@.resourceRelationship[?(@.relationshipType=="targets" && @.resource.@referredType=="Core" && @.resource.id=="${coreId}")])]`
    ]
  ]);

  const adminStateFilters = new Set(selectedFilters);
  if (
    adminStateFilters.size > 0 &&
    !(adminStateFilters.has(SIMStatus.Enabled) && adminStateFilters.has(SIMStatus.Disabled))
  ) {
    adminStateFilters.forEach(f => {
      params.append(
        "filter",
        `$[?(@.resourceCharacteristic[?(@.name=="AdminState" && @.value=="${f}")])]`
      );
    });
  }

  if (size && page) {
    params.set("offset", size ? `${+size * (+page - 1)}` : "0");
    params.set("limit", size);
  }

  if (imsiFilter) {
    params.append(
      "filter",
      `$[?(@.resourceCharacteristic[?(@.name=="IMSI" && @.value *="${imsiFilter}")])]`
    );
  }

  return params;
}

export function mpnSIMRequestUrl(params: SimRequestParams) {
  return new URL(
    `/mobile-networks/sim/tmf-api/resourceInventoryManagement/v4/resource?${mpnSIMRequestParams(
      params
    )}`,
    window.location.origin
  );
}
