import {
  ChangeEvent,
  ComponentType,
  createElement,
  HTMLProps,
  InputHTMLAttributes,
  useCallback
} from "react";
import { IStyledComponent } from "styled-components";

import { UiNode, UiNodeInputAttributes } from "@ory/client";

interface NodeInputProps {
  node: UiNode;
  attributes: UiNodeInputAttributes;
  disabled: boolean;
  value: string;
  placeholder: string;
  setValue?: (value: string) => void;
  input:
    | ComponentType<InputHTMLAttributes<HTMLInputElement>>
    | IStyledComponent<"web", InputHTMLAttributes<HTMLInputElement>>;
}

const SubmitInput = (props: HTMLProps<HTMLInputElement>) =>
  createElement("input", { ...props, className: "auth-button" });

export function NodeInput({
  node,
  attributes,
  value,
  disabled,
  input,
  placeholder,
  setValue
}: NodeInputProps) {
  const textValue = attributes.type === "submit" ? node.meta.label?.text : value;
  const Input = attributes.type === "submit" ? SubmitInput : input;

  const onChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      setValue(e.target.value);
    },
    [setValue]
  );

  return (
    <Input
      placeholder={placeholder}
      title={node.meta.label?.text}
      type={attributes.type}
      name={attributes.name}
      value={textValue}
      onChange={onChange}
      disabled={attributes.disabled || disabled}
    />
  );
}
