import { InputHTMLAttributes, TdHTMLAttributes, ThHTMLAttributes } from "react";
import { IStyledComponent, styled } from "styled-components";

import { Skeleton } from "../Skeleton";
import { Table, Td, Th, THead, Tr } from "./Table";

const SkeletonWrapper = styled.div<{ $tableMargin: string }>`
  width: 100%;
  margin: ${({ $tableMargin }) => $tableMargin};
  box-sizing: border-box;
`;

const SkeletonTable = styled(Table)`
  table-layout: fixed;
`;

interface TableSkeletonProps {
  columns: (number | string | null)[];
  margin?: string;
  itemWidth?: number | string;
  rowNumber?: number;
  StyledTr?: IStyledComponent<"web", InputHTMLAttributes<HTMLTableRowElement>>;
  StyledTd?: IStyledComponent<"web", TdHTMLAttributes<HTMLTableCellElement>>;
  StyledTh?: IStyledComponent<"web", ThHTMLAttributes<HTMLTableCellElement>>;
}

const SkeletonTd = styled.td`
  padding: 0 10px;
`;

const SkeletonTr = styled.tr`
  td {
    background-color: white;
  }

  &:hover {
    box-shadow: none;
    background-color: white;
    td {
      background-color: white;
      &:first-child {
        box-shadow: none;
      }
    }
  }
`;

export function TableSkeleton({
  columns,
  margin = "30px auto",
  itemWidth,
  rowNumber = 5,
  StyledTr = Tr,
  StyledTd = Td,
  StyledTh = Th
}: TableSkeletonProps) {
  const tableColumns = columns.map((width, i) => ({
    width,
    Cell: () => <Skeleton />,
    id: `${i}`
  }));

  const rows = [...Array(rowNumber).keys()];

  return (
    <SkeletonWrapper $tableMargin={margin}>
      <SkeletonTable>
        <colgroup>
          {tableColumns.map((c, i) => (
            <col key={i} style={{ width: c.width }} />
          ))}
        </colgroup>
        <THead>
          <tr>
            {tableColumns.map(col => (
              <StyledTh key={col.id}></StyledTh>
            ))}
          </tr>
        </THead>
        <tbody>
          {rows.map(i => (
            <SkeletonTr as={StyledTr} key={i}>
              {tableColumns.map(col => (
                <SkeletonTd as={StyledTd} key={`${i}-${col.id}`}>
                  <Skeleton width={itemWidth} />
                </SkeletonTd>
              ))}
            </SkeletonTr>
          ))}
        </tbody>
      </SkeletonTable>
    </SkeletonWrapper>
  );
}
