import { InputHTMLAttributes, ReactNode } from "react";
import styled, { IStyledComponent } from "styled-components";

import { ButtonGroup } from "@src/Components/Buttons/ButtonGroup";
import { GhostButton } from "@src/Components/Buttons/Ghost";
import { PrimaryButton } from "@src/Components/Buttons/Primary";

export const Wrapper = styled(ButtonGroup)<{ $alignRight: boolean }>`
  display: flex;
  justify-content: ${({ $alignRight }) => $alignRight && "flex-end"};
  margin: 32px 0 32px auto;
  grid-column: 1 / -1;
`;

export interface ButtonRowProps {
  onClickBack: () => void;
  onClickSubmit?: () => void;
  isValid?: boolean;
  isSubmitting?: boolean;
  backText: string;
  submitText: string;
  alignRight?: boolean;
  SubmitButton?: IStyledComponent<"web", InputHTMLAttributes<HTMLButtonElement>>;
  BackButtonButton?: IStyledComponent<"web", InputHTMLAttributes<HTMLButtonElement>>;
}

export function ButtonRow({
  onClickBack,
  onClickSubmit,
  isValid = true,
  isSubmitting = false,
  backText,
  submitText,
  alignRight = false,
  SubmitButton = PrimaryButton,
  BackButtonButton = GhostButton
}: ButtonRowProps) {
  const isDisabled = !isValid || isSubmitting;
  return (
    <Wrapper $alignRight={alignRight}>
      <BackButtonButton type="button" onClick={onClickBack}>
        {backText}
      </BackButtonButton>
      <ActionButton
        disabled={isDisabled}
        onClickSubmit={onClickSubmit}
        ButtonComponent={SubmitButton}
      >
        {submitText}
      </ActionButton>
    </Wrapper>
  );
}

export interface ActionButtonProps {
  disabled: boolean;
  onClickSubmit?: () => void;
  children: ReactNode;
  ButtonComponent: IStyledComponent<"web", InputHTMLAttributes<HTMLButtonElement>>;
}

function ActionButton({ disabled, onClickSubmit, ButtonComponent, children }: ActionButtonProps) {
  const isSubmit = onClickSubmit == undefined;

  return isSubmit ? (
    <ButtonComponent disabled={disabled} type="submit">
      {children}
    </ButtonComponent>
  ) : (
    <ButtonComponent disabled={disabled} type="button" onClick={onClickSubmit}>
      {children}
    </ButtonComponent>
  );
}
