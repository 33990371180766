import { useSelect, UseSelectProps } from "downshift";
import { HTMLAttributes, ReactNode } from "react";
import { css, IStyledComponent, styled } from "styled-components";

import ArrowDownDD from "@img/arrow_light_down.svg";
import ArrowUpDD from "@img/arrow_light_up.svg";

import { DropdownMenuItemWrapper, Menu, MenuAnchor } from "./DropdownMenu";
import { baseInputStyles } from "./Input/Input";

const disabledStyles = css`
  opacity: 0.5;
  color: #777777;
  background-color: ${({ theme }) => theme.backgroundLight};
  cursor: not-allowed;
`;

export const Select = styled.div<{ $disabled: boolean }>`
  ${baseInputStyles}
  ${({ $disabled }) => $disabled && disabledStyles}
  position: relative;
  height: auto;
  &:hover {
    cursor: pointer;
  }
`;

const SelectTextWrapper = styled.div`
  padding-right: 30px;
  overflow-x: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

const arrowsCSS = css`
  position: absolute;
  margin: auto;
  height: 12px;
  width: 12px;
  top: 0;
  bottom: 0;
  right: 16px;
`;

const ArrowUp = styled(ArrowUpDD)`
  ${arrowsCSS}
`;
const ArrowDown = styled(ArrowDownDD)`
  ${arrowsCSS}
`;

export const LabelDropdownWrapper = styled.div`
  display: grid;
  grid-template-columns: auto 1fr 2fr;
  align-items: baseline;
  justify-items: end;
  gap: 10px;
`;

function defaultGetItem<T>(item: T) {
  return item ? String(item) : null;
}

interface DropdownProps<T> extends UseSelectProps<T> {
  SelectElement?: IStyledComponent<"web", HTMLAttributes<HTMLDivElement>>;
  OptionElement?: IStyledComponent<"web", HTMLAttributes<HTMLDivElement>>;
  getItemDisplayName?: (item: T) => ReactNode;
  getItemKey?: (item: T) => ReactNode;
  displayText?: string;
  placeholder?: string;
  disabledMessage?: string;
  loading?: boolean;
  handleOverflow?: boolean;
}

export function Dropdown<T>({
  SelectElement = Select,
  OptionElement = DropdownMenuItemWrapper,
  getItemDisplayName = defaultGetItem,
  getItemKey = defaultGetItem,
  displayText,
  placeholder = "select item",
  disabledMessage,
  loading = false,
  handleOverflow = true,
  ...props
}: DropdownProps<T>) {
  const { items, initialSelectedItem, onSelectedItemChange } = props;
  const {
    getToggleButtonProps,
    selectedItem,
    getMenuProps,
    isOpen,
    highlightedIndex,
    getItemProps
  } = useSelect({
    items,
    initialSelectedItem,
    onSelectedItemChange
  });
  const disabled = !!disabledMessage;
  const selectText = loading
    ? "loading..."
    : disabled
    ? disabledMessage
    : displayText || getItemDisplayName(selectedItem) || placeholder;

  return (
    <>
      <SelectElement $disabled={disabled} {...getToggleButtonProps({ disabled })}>
        {handleOverflow ? <SelectTextWrapper>{selectText}</SelectTextWrapper> : selectText}
        {isOpen ? <ArrowUp height={12} width={12} /> : <ArrowDown height={12} width={12} />}
      </SelectElement>
      <MenuAnchor {...getMenuProps()}>
        {isOpen && (
          <Menu>
            {items.map((item, index) => (
              <OptionElement
                key={getItemKey(item)}
                $highlighted={highlightedIndex === index}
                {...getItemProps({ item, index })}
              >
                {getItemDisplayName(item)}
              </OptionElement>
            ))}
          </Menu>
        )}
      </MenuAnchor>
    </>
  );
}
