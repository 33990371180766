import "react-loading-skeleton/dist/skeleton.css";

import { lazy, Suspense } from "react";
import { ErrorBoundary } from "react-error-boundary";
import { Toaster } from "react-hot-toast";
import { Navigate, Outlet, Route, Routes } from "react-router-dom";

import { LoadingPage } from "@src/Components/Loading/LoadingPage";
import { NotFoundPage } from "@src/Components/Navigation/NotFoundPage";
import { ProtectedDashboard } from "@src/Components/Navigation/ProtectedRoute";

import { Error } from "./Auth/Error";
import { Login } from "./Auth/Login";
import { Recover } from "./Auth/Recover";
import { ApolloProviderWithOrgCtx } from "./Clients/Apollo/ApolloProvider";
import { DefaultPageRedirect } from "./DefaultPageRedirect";
import { AppErrorFallBack, appFallbackMsg } from "./Error/AppErrorFallback";
import { NoDashboard } from "./Error/NoDashboard";
import { NoOrg } from "./Error/NoOrg";
import { AboutUserSettingsRoutes } from "./GeneralPagesRoutes";
import { useLandingPage } from "./Hooks/Permissions/landingPage";
import { useMECDashboard } from "./Hooks/Permissions/mecDashboard";
import { InitialRedirect } from "./InitialRedirect";
import { Layout } from "./Layout";
import { InitialNetworkRedirect } from "./MPNDashboard/MPNRedirects";
import { EditNetworkForm } from "./MPNDashboard/Network/EditNetworkForm";
import { NewNetworkForm } from "./MPNDashboard/Network/NewNetworkForm";
import { OrgRedirect } from "./OrgRedirect";

const LandingPage = lazy(() => import("./Landing/LandingPage"));
const AddNetworkPage = lazy(() => import("./MPNDashboard/Network/AddNetworkPage"));
const AdminDashboardRoutes = lazy(() => import("./AdminDashboard/AdminRoutes"));
const OrgLessAdminRoutes = lazy(() => import("./AdminDashboard/OrgLessAdminRoutes"));
const Auth = lazy(() => import("./Auth/Auth"));
const ServicesDashboardRoutes = lazy(
  () => import("@src/ServicesDashboard/ServicesDashboardRoutes")
);
const MPNRoutes = lazy(() => import("./MPNDashboard/MPNRoutes"));
const MECRoutes = lazy(() => import("./MECDashboard/MECRoutes"));

export function App() {
  const { allowed: showMEC } = useMECDashboard();
  const { allowed: showLanding } = useLandingPage();
  return (
    <>
      <Routes>
        <Route index element={<InitialRedirect />} />

        <Route
          path="app"
          element={
            <ErrorBoundary
              fallback={
                <AppErrorFallBack
                  title="Something went wrong while setting the Dashboard Layout."
                  msg={appFallbackMsg}
                />
              }
            >
              <Layout />
            </ErrorBoundary>
          }
        >
          <Route path="services" element={<OrgRedirect />} />
          <Route path="service-designer" element={<OrgRedirect />} />
          <Route path="publisher" element={<OrgRedirect />} />
          <Route path="infrastructure" element={<OrgRedirect />} />
          <Route path="reports" element={<OrgRedirect />} />
          <Route path="settings" element={<OrgRedirect />} />

          <Route path="no-org" element={<NoOrg />} />

          <Route path="admin/*" element={<OrgLessAdminRoutes />} />

          <Route path=":orgCtx" element={<ApolloProviderWithOrgCtx />}>
            <Route index element={<DefaultPageRedirect />} />

            <Route path="no-dashboard" element={<NoDashboard />} />

            {showLanding && <Route path="home" element={<LandingPage />} />}

            <Route
              path="services/*"
              element={
                <ProtectedDashboard dashboard="services">
                  <ServicesDashboardRoutes />
                </ProtectedDashboard>
              }
            />

            <Route path="mpn" element={<Outlet />}>
              <Route index element={<InitialNetworkRedirect />} />
              <Route
                path=":networkCtx/*"
                element={
                  <ProtectedDashboard dashboard="mpn">
                    <MPNRoutes />
                  </ProtectedDashboard>
                }
              />
              <Route
                path="network"
                element={
                  <ProtectedDashboard dashboard="mpn">
                    <AddNetworkPage />
                  </ProtectedDashboard>
                }
              >
                <Route index element={<Navigate to="add" replace />} />
                <Route path="add" element={<NewNetworkForm />} />
                <Route path=":networkCtx/edit" element={<EditNetworkForm />} />
              </Route>
            </Route>

            {showMEC && <Route path="mec/*" element={<MECRoutes />} />}

            <Route path="admin/*" element={<AdminDashboardRoutes />} />
          </Route>

          <Route path="*" element={<AboutUserSettingsRoutes />} />
        </Route>

        <Route
          path="auth"
          element={
            <Suspense fallback={<LoadingPage />}>
              <Auth />
            </Suspense>
          }
        >
          <Route path="login" element={<Login />} />
          <Route path="recovery" element={<Recover />} />
          <Route path="error" element={<Error />} />
        </Route>
        <Route path="*" element={<NotFoundPage />} />
      </Routes>
      <Toaster />
    </>
  );
}
