import { H1, H3 } from "../Text";

export function NotFoundPage() {
  return (
    <div>
      <H1>Not found</H1>
      <p>The page that you&apos;re looking for cannot be found.</p>
      <H3>Possible causes</H3>
      <ul>
        <li>The URL is incorrect</li>
        <li>Your user doesn&apos;t have the required permissions to view the page</li>
      </ul>
    </div>
  );
}
