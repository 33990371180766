import { ReactNode, SVGFactory } from "react";
import styled from "styled-components";

import { ExternalIconLink, IconLink } from "./IconLink";
import { NavIcon } from "./NavIcon";

const NavText = styled.div`
  margin-top: 5px;
`;

interface NavItemProps {
  linkTo: string;
  icon: SVGFactory;
  label: ReactNode;
  disabled?: boolean;
  external?: boolean;
}

export function NavItem({ linkTo, label, icon, disabled, external }: NavItemProps) {
  const Icon = icon;

  const children = (
    <>
      <NavIcon>
        <Icon height={40} width={40} />
      </NavIcon>
      <NavText>{label}</NavText>
    </>
  );

  return external ? (
    <ExternalIconLink href={disabled ? undefined : linkTo} target="_blank">
      {children}
    </ExternalIconLink>
  ) : (
    <IconLink disabled={disabled} to={linkTo}>
      {children}
    </IconLink>
  );
}
